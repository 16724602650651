<template>
  <div class="column is-half">
    <div class="is-flex align-items-center pb-075">
      <intersect-icon v-if="parentField" class="has-tooltip-right" :color="'has-text-primary'"
                      :data-tooltip="`Linked to field ${parentField.label}`"/>
      <intersect-icon v-else class="has-text-grey"/>
      <p class="ml-1 has-text-weight-semibold">{{ field.label }}<span v-if="field.is_required" class="has-text-danger pl-025">*</span> </p>
    </div>
    <div :class="{ 'has-tooltip' : awaitingParentValue }"
         :data-tooltip="valueTooltip" >
      <text-input
          v-if="!fieldOptions && !parentField"
          placeholder="Enter attribute value"
          :value="fieldValue"
          @input="updateAttribute">
        <action-button v-if="fieldValue" slot="right" @click="removeAttribute" class="">
          <icon icon="times"/>
        </action-button>
      </text-input>
      <text-input-list
          v-if="fieldOptions && !field.require_predefined_value"
          placeholder="Enter attribute value"
          :value="fieldValue"
          :data-list="fieldOptions"
          :disabled="awaitingParentValue"
          @input="updateAttribute">
        <action-button v-if="fieldValue" slot="right" @click="removeAttribute" class="">
          <icon icon="times"/>
        </action-button>
      </text-input-list>
      <data-selector
          v-if="fieldOptions && field.require_predefined_value"
          :items="fieldOptions"
          :disabled="awaitingParentValue"
          value-key="value"
          label-key="value"
          @input="updateAttribute"
          :value="fieldValue">
        <action-button v-if="fieldValue" slot="right" @click="removeAttribute" class="">
          <icon icon="times"/>
        </action-button>
      </data-selector>
    </div>

<!--    {{field}}-->
  </div>
</template>
<script>
import {mapGetters} from 'vuex'

export default {
  props: {
    field: {
      type: Object,
      default: () => ({})
    }
  },

  created() {
    this.refreshNestedState()
  },

  data: () => ({
    parentField: null,
    parentFieldValue: null,
    fieldValue: null,
    fieldOptions: []
  }),

  methods: {
    updateAttribute(value) {
      this.$store.commit('asset/updateAttribute', {
        fieldId: this.field.field_id,
        value: value,
      })
      this.refreshNestedState()
    },
    refreshNestedState(){
      this.setParentField()
      this.setParentFieldValue()
      this.getFieldValue()
      this.getFieldOptions()
    },
    async removeAttribute() {
      if (await this.$confirm({
        title: 'Remove Attribute',
        message: 'Are you sure you want to clear this attribute?'
      })) {
        this.$store.commit('asset/removeAttribute', this.field.field_id)
        this.refreshNestedState()
        console.log(this.field.field_id)
      }
    },
    setParentField() {
      const fields = JSON.parse(this.asset.type.custom_fields)
      this.parentField = fields.find(field => field.field_id === this.field.linked_field)
    },
    setParentFieldValue() {
      this.parentFieldValue = this.asset.meta_data?.[this.field.linked_field]
    },
    getFieldValue() {
      if (this.asset.meta_data && this.asset.meta_data[this.field.field_id]) {
        this.fieldValue = this.asset.meta_data[this.field.field_id]
        return
      }
      this.fieldValue = ''
    },
    getFieldOptions() {
      if (!this.field.linked_field){
        this.fieldOptions = this.field.options
        return
      }
      this.fieldOptions = this.field.options?.filter(field => field.linked_value === this.parentFieldValue)
    },
  },

  computed: {
    ...mapGetters('asset', [
      'asset'
    ]),
    awaitingParentValue(){
      return this.parentField && !this.parentFieldValue
    },
    valueTooltip() {
      return this.awaitingParentValue ? `First select a value for the linked field ${this.parentField?.label}` : null
    }

  },

  watch: {
    "asset":{
      handler() {
        this.setParentField()
        this.setParentFieldValue()
        this.getFieldValue()
        this.getFieldOptions()
      },
      deep: true,
    }
  }
}
</script>