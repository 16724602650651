<template>
  <loader v-bind="{ loading }">
    <div class="is-flex justify-between">
      <h4 class="has-text-weight-semibold mb-1">Changing {{ Naming.Zone }}</h4>
      <action-button
          @click="resetZone()"
          class="is-info-lighter">
        Reset {{ Naming.Zone }} To Parent {{ Naming.Asset }} {{ Naming.Zone }}
      </action-button>
    </div>
    <data-selector
        class="column is-8"
        searchable
        right-icon="chevron-down"
        @input="selectSite"
        v-model="selectedSiteId"
        :items="sites"
        :disabled="sites.length < 1"
        value-key="id"
        label-key="name">
      <action-button
          class="is-primary"
          v-if="selectedSiteId"
          slot="right"
          @click="clearSite">
        <icon icon="times"/>
      </action-button>
      Select {{ Naming.Site }}
    </data-selector>
    <data-selector
        v-if="selectingZone"
        class="column is-8"
        searchable
        right-icon="chevron-down"
        v-model="selectedZoneId"
        @input="selectZone"
        :items="zoneList"
        :disabled="zoneList.length < 1"
        value-key="id"
        label-key="name">
      <action-button
          class="is-primary"
          v-if="selectedZoneId"
          slot="right"
          @click="this.selectedZoneId = null">
        <icon icon="times"/>
      </action-button>
      Select {{ Naming.Zone }}
    </data-selector>
  </loader>
</template>

<script>

import sort from "@/utils/sortArray";
import {mapGetters} from "vuex";

export default {
  name: 'change-zone',

  props: {
    previousZone: {
      type: Object
    }
  },

  data: () => ({
    sites: [],
    selectedSiteId: '',
    selectedZoneId: '',
    selectingZone: false,
    loading: true
  }),

  created() {
    this.loadSites()
  },

  methods: {
    async loadSites() {
      await this.$store.dispatch('site/loadSiteList').then((data) => {
        this.sites = data
      }).catch((error) => {
        this.$whoops()
        this.loading = false
      }).finally(() => {
        this.selectedSiteId = this.previousZone.site_id
        this.selectSite()
      })
    },
    selectSite() {
      this.$store.dispatch('zone/loadZoneList', this.selectedSiteId)
      this.selectingZone = true
      this.loading = false
    },
    selectZone() {
      const zone = this.zoneList.find(zone => zone.id === this.selectedZoneId)
      this.$emit('zoneSelected', zone)
    },
    resetZone() {
      this.$emit('zoneSelected', null)
    },
    clearSite() {
      this.selectingZone = false
      this.selectedZoneId = ''
      this.selectedSiteId = ''
    },
  },
  computed: {
    ...mapGetters('zone', ['zoneList'])
  }
}
</script>