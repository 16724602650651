<template>
  <div class="position-relative mr-1" :class="[imageClass]">
    <div v-if="imageUrl && location === 'overview'" class="clear-image-icon">
      <icon @click="clearImage" icon="times-circle" class="has-text-grey hover-highlight-danger"/>
    </div>
    <img v-if="imageUrl" :src="imageUrl" alt="Asset Image" :style="imageStyle"/>
    <div v-else
         :style="imageStyle"
         class="is-flex align-items-center justify-center rounded-sml"
         :class="[backgroundClass]">
      <q-r-code-icon style="margin: 1px 0 0 1px" color="has-text-cool-blue-text" :size="iconClass"/>
    </div>
    <div v-if="location === 'overview'" class="hover-camera-icon"  @click="openImage" >
      <icon icon="edit" class="has-text-grey hover-highlight-primary"/>
    </div>
  </div>
</template>

<script>
import QRCodeIcon from "@/components/ui/icons/QRCodeIcon.vue";
import {mapGetters} from "vuex";
import {uploadAssetImage} from "@/modals";

export default {
  computed: {
    ...mapGetters('asset', ['asset']),
    imageClass() {
      return this.location === 'thumbnail' ? 'rounded-sml' : 'rounded-sml show-on-hover';
    },
    imageStyle() {
      const baseStyle = {
        objectFit: 'scale-down',
        objectPosition: 'center',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      };
      return this.location === 'thumbnail'
          ? {...baseStyle, width: '45px', height: '45px', borderRadius: '50%'}
          : {...baseStyle, width: '150px', height: '150px', borderRadius: '5px'};
    },
    iconClass() {
      return this.location === 'thumbnail' ? 16 : 60;
    },
    backgroundClass() {
      return this.location === 'thumbnail' ? 'has-background-primary-light' : 'has-background-primary-light';
    }
  },

  components: {QRCodeIcon},

  props: {
    imageUrl: {
      type: String,
      required: false,
      default: null
    },
    location: {
      type: String,
      required: true,
      validator: value => ['thumbnail', 'overview'].includes(value)
    }
  },

  methods: {
    clearImage() {
      this.$store.dispatch('asset/clearImage', this.asset.id)
        .then(() => {
        })
        .catch(() => {
          this.$store.dispatch('asset/loadOverview', this.assetId)
        })
    },
    openImage() {
      uploadAssetImage()
    },
  }

}
</script>

<style>
.position-relative {
  position: relative;
}

.hover-camera-icon {
  display: none;
  position: absolute;
  bottom: 3px;
  right: 5px;
}

.clear-image-icon {
  display: none;
  position: absolute;
  top: 3px;
  right: 5px;
}

.show-on-hover:hover .hover-camera-icon{
  display: block;
}

.show-on-hover:hover .clear-image-icon {
  display: block;
}
</style>