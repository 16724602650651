<template>
  <div>
    <has-parent-asset v-if="asset.asset_id !== null" :asset="asset"></has-parent-asset>

    <loader v-else v-bind="{ loading }" text="Loading Components">
      <div class="level mb-1">
        <div class="level-left">
          <h3 class="is-size-5 level-item">Assign Components to Parent {{ Naming.Asset }}</h3>
        </div>
        <div class="level-right">
          <action-button
              v-if="canTransferSameClient"
              @click="startZoneSelection"
              class="is-info-lighter mr-1">
            Change {{ Naming.Zone }}
          </action-button>
          <action-button
              :disabled="loading"
              @click="syncSubAssets"
              class="is-success"
              left-icon="check-circle">
            Save Components
          </action-button>
        </div>
      </div>
      <div class="box">
        <div class="grid has-2-columns gap-1">
          <!--ASSIGNED ASSETS-->
          <div class="is-1-columns">
            <div class="tab">Assigned To {{ asset.code }}</div>
            <draggable :list="selectedSubAssets" group="subAssetSelector"
                       class="sub-asset-draggable has-background-white-bis is-fullheight">
              <div v-if="hasSubAssets" v-for="(subAsset, index) in selectedSubAssets" :key="subAsset.id">
                <sub-asset-item :available-items="false" :index="index"
                                :sub-asset="subAsset" :staged="stagedSubAssets"
                                @removeFromSelected="removeAssetFromSelected"/>
              </div>
              <div v-if="!hasSubAssets" class="has-text-grey p-1">No sub-{{ Naming.Assets.toLowerCase() }} assigned to parent {{ Naming.Asset.toLowerCase() }} yet. Select or drag from available {{ Naming.Assets.toLowerCase() }}.</div>
            </draggable>
          </div>

          <!--AVAILABLE ASSETS-->
          <div class="is-1-columns">
            <div class="tab available is-flex justify-between">
              <div class="">Available {{ Naming.Assets }} in {{ Naming.Zone }}:
                <span class="has-text-weight-bold">{{selectedZone.name}}</span>
              </div>
              <div v-if="!selectingZone">
                Showing {{availableSubAssetsCount}} of {{calculatedTotalAssets}}
              </div>
            </div>
            <div v-if="selectingZone" class="p-1 has-background-white-bis">
              <change-zone @zoneSelected="setZone" :previous-zone="selectedZone ?? asset.zone"/>
            </div>

            <div v-else>
              <div class="search-box grid has-4-columns gap-1">
                <div class="is-flex is-3-columns">
                  <input
                      type="text"
                      v-model="search"
                      class="input"
                      placeholder="Search by internal ID or barcode"
                      @input="debouncedLoadAvailableComponents"
                  />
                  <div class="is-flex">
                    <action-button
                        v-if="search"
                        @click="clearSearch()"
                        class="is-info-lighter">
                      <span>Clear</span>
                      <icon icon="times"/>
                    </action-button>
                  </div>
                </div>
                <action-button @click="selectAll()" class="is-info-lighter">Select All</action-button>
                <action-button left-icon="sort" @click="sortAssets('code')" >Internal Id</action-button>
                <action-button left-icon="sort" @click="sortAssets('created_at')" >Onboard Date</action-button>
                <action-button left-icon="sort" @click="sortAssets('type_name')" >{{ Naming.AssetType }}</action-button>
              </div>

              <draggable :list="this.availableSubAssets.data" group="subAssetSelector" class="sub-asset-draggable">
                <div v-for="(availableSubAsset, index) in this.availableSubAssets.data" :key="availableSubAsset.id">
                  <sub-asset-item v-if="!loadingComponents" :is-available-items="true"
                                  :index="index" class="available-item"
                                  :sub-asset="availableSubAsset" @pushToSelected="pushAssetToSelected"/>
                </div>
                <loader v-if="loadingMoreComponents" v-bind="{ loadingMoreComponents }" text="Loading More Components"></loader>
                <div v-if="moreAssetsAvailable">
                  <div @mouseenter="handleHoverOnIcon"
                       class="load-more-box has-text-grey is-flex-column align-items-center justify-between">
                    <div class="has-text-grey has-text-centered p-05">
                      Showing {{availableSubAssetsCount}} of {{calculatedTotalAssets}}
                    </div>
                    <icon class="is-size-5" icon="plus-circle "></icon>
                  </div>
                </div>
                <!--placeholder to give height when no subasset items-->
                <div class="p-2" >&nbsp</div>
              </draggable>
              <div v-if="!this.availableSubAssets.data">
                <div class="has-text-grey p-05">No additional available {{ Naming.Assets.toLowerCase() }} in this {{ Naming.Zone.toLowerCase() }}. Please select a different {{ Naming.Zone.toLowerCase() }}.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </loader>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import SubAssetItem from "@/views/asset/manager/partials/SubAssetItem.vue";
import ChangeZone from "@/views/asset/manager/partials/ChangeZone.vue";
import sortArray from "@/utils/sortArray";
import {debounce, find} from "lodash";
import {common as backend} from "@/api";
import HasParentAsset from "@/views/asset/manager/partials/HasParentAsset.vue";

export default {
  name: 'Components',

  components: {HasParentAsset, ChangeZone, SubAssetItem},

  data: () => ({
    loading: true,
    loadingComponents: false,
    loadingMoreComponents: false,
    selectingZone: false,
    selectedZone: null,
    search: '',
    sortDescending: {
      code: false,
      created_at: false,
      type_name: false,
    }
  }),

  created() {
    this.loadAvailableComponents()
    this.loadAssetComponents()
    this.selectedZone = this.asset.zone
  },

  methods: {
    async loadAssetComponents() {
      this.loading = true
      await this.callStoreAction("asset/loadSubAssets", this.asset.id).then(() => this.loading = false)
    },
    async loadAvailableComponents() {
      this.loadingComponents = true
      let payload = this.getPayload()
      await this.callStoreAction("asset/loadAvailableSubAssets", payload).then(() => this.loadingComponents = false)
    },
    async syncSubAssets() {
      this.loading = true
      let payload = {
        assetId: this.asset.id,
        sub_asset_ids: this.selectedSubAssets.map(asset => asset.id)
      }
      await this.callStoreAction("asset/syncSubAssets", payload).then(() => this.loading = false)
      this.selectedZone = this.asset.zone
      await this.loadAvailableComponents()
    },
    async callStoreAction(action, payload) {
      await this.$store.dispatch(
          action,
          payload
      ).catch((error) => {
        console.log(error)
        this.$whoops()
      })
    },
    getPayload() {
      return {
        asset_id: this.asset.id,
        zone_id: this.selectedZone?.id ?? this.asset?.zone_id,
        search: this.search,
        sub_asset_ids: this.selectedAssetIds
      }
    },
    pushAssetToSelected(asset) {
      let index = this.availableSubAssets.data.indexOf(asset);
      this.availableSubAssets.data.splice(index, 1);
      this.selectedSubAssets.push(asset);
    },
    removeAssetFromSelected(asset) {
      this.$store.commit('asset/removeSubAsset', asset)
      const zoneId = this.selectedZone?.id ?? this.asset.zone_id
      if (asset.zone_id === zoneId) {
        this.availableSubAssets.data.push(asset)
      }
    },
    clearSearch() {
      this.search = ''
      this.loadAvailableComponents()
    },
    selectAll() {
      this.availableSubAssets.data.forEach(asset => this.selectedSubAssets.push(asset))
      this.loadAvailableComponents()
    },
    handleHoverOnIcon() {
      this.loadMoreSubAssets()
    },
    debouncedLoadMoreSubAssets: debounce(function () {
      this.loadingMoreComponents = true
      let payload = this.getPayload()
      this.apiRequest(payload)
    }, 1000),
    apiRequest(payload) {
      backend.loadPathPost(
          this.availableSubAssets.links.next,
          payload,
          ({data}) => {
            this.availableSubAssets.links.next = data.links.next
            this.availableSubAssets.meta = data.meta
            data.data.forEach(asset => this.availableSubAssets.data.push(asset))
          },
          error => {
          })
          .finally(() => {
            this.loadingMoreComponents = false
          })
    },
    async loadMoreSubAssets() {
      await this.debouncedLoadMoreSubAssets();
    },
    setZone(zone) {
      this.selectedZone = zone ?? this.asset.zone
      this.selectingZone = false
      this.clearSearch()
    },
    async startZoneSelection() {
      if (await this.$confirm({
        title: this.Convert('Transferring Assets To Parent Zone'),
        message: this.Convert('Adding asset components from another zone will transfer the component asset into the parent asset zone. Are you sure you want to save the current selection and continue?'),
        confirmClass: 'is-warning'
      })) {
        this.selectingZone = true
        await this.syncSubAssets()
      }
    },
    canTransferSameClient() {
      return find(this.user.abilities, ability => ability.name === 'transfer-assets-same-client') ?? false
    },
    sortAssets(key) {
      let isDescending = this.sortDescending[key]
      this.availableSubAssets.data = sortArray(this.availableSubAssets.data, key, isDescending)
      this.sortDescending[key] = !this.sortDescending[key]
    }
  },

  computed: {
    ...mapGetters("asset", ["asset", "availableSubAssets", "selectedSubAssets"]),
    ...mapGetters("site", ["site"]),
    ...mapGetters('auth', ['user']),
    hasSubAssets() {
      return this.selectedSubAssets.length > 0
    },
    debouncedLoadAvailableComponents() {
      return debounce(this.loadAvailableComponents, 500);
    },
    moreAssetsAvailable() {
      return this.availableSubAssets.links?.next?.length > 0
    },
    stagedSubAssets() {
      if (this.selectedSubAssets && this.asset.subAssets) {
        return this.selectedSubAssets.filter(asset => !this.asset.subAssets.includes(asset))
      }
    },
    selectedAssetIds() {
      if (this.selectedSubAssets) {
        return this.selectedSubAssets.map(asset => asset.id)
      }
      if (this.asset.subAssets) {
        return this.asset.subAssets.map(asset => asset.id)
      }
      if (this.asset.sub_assets) {
        return this.asset.sub_assets.map(asset => asset.id)
      }
      return []
    },
    calculatedTotalAssets() {
      if (this.moreAssetsAvailable) {
        const toBeLoaded = this.availableSubAssets?.meta.total - this.availableSubAssets?.meta.to
        return this.availableSubAssetsCount + toBeLoaded
      }
      return this.availableSubAssetsCount
    },
    availableSubAssetsCount(){
      return this.availableSubAssets?.data?.length
    }
  },
}
</script>