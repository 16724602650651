<template>
<loader v-bind="{ loading }">
  <div class="box">
    <div class="columns">
      <div class="column">
        <h4 class="is-size-4 has-text-weight-light">{{ Naming.Asset }} Information</h4>
      </div>
    </div>
    <form>
      <div class="columns">
        <div class="column">
          <text-input 
            :error="$root.errors.code"
            :value="asset.code"
            @input="code">
            Identification No.
          </text-input>
        </div>
        <div class="column">
          <data-selector
            :value="asset.zone_id"
            @input="zone"
            :items="zoneList"
            label-key="name"
            value-key="id">{{ Naming.Zone }}</data-selector>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <select-input 
            no-revert
            :items="riskLevelList"
            value-key="id"
            label-key="level"
            :error="$root.errors.code"
            :value="asset.risk_id" 
            @input="risk">
            Risk Level
          </select-input>
        </div>
        <div class="column">
          <select-input 
            no-revert
            :items="assetTypeList"
            value-key="id"
            label-key="name"
            :error="$root.errors.asset_type_id"
            :value="asset.asset_type_id" 
            @input="assetType">
            {{ Naming.AssetType }}
          </select-input>
        </div>
      </div> 
    </form>

    <div class="mt-1 mb-1" v-if="assetTypeHasMetadata === true">
      <div class="is-flex align-items-center">
        <braces-icon :size="24" :color="'has-text-primary'" style="margin-bottom: 0.3rem; margin-right: 0.3rem" />
        <h4 class="is-size-4 has-text-weight-light">Custom Attributes</h4>
      </div>
        <p class="has-text-grey">Custom attributes are derived from the {{ Naming.AssetType }}</p>
      <div class="mt-1 mb-2 columns is-multiline">
        <meta-field
          :field="field"
          v-for="(field, index) in asset.type.custom_fields_object"
          :key="index"/>
      </div>
      <message class="mt-1 is-warning" v-if="!canSave" >One of the required custom attributes does not have a value assigned.</message>
    </div>
    <div class="columns">
      <div class="column buttons is-right">
        <submit-button :working="working" :disabled="!canSave" class="is-rounded-sml is-success" left-icon="save" @submit="save">Save {{ Naming.Asset }}</submit-button>
      </div>
    </div>
  </div>

</loader>
 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import MetaField from '../partials/MetaField'

export default {

  components: {
    MetaField
  },

  data: () => ({
    working: false,
    loading: true
  }),
  
  beforeCreate() {
    Promise.all([
      this.$store.dispatch('riskLevel/loadRiskLevelList'),
      this.$store.dispatch('assetType/loadAssetTypeList')
    ]).then(() => {
      this.loading = false
    })
  },

  mounted() {
    const siteId = this.asset.zone.site_id
    this.$store.dispatch('zone/loadZoneList', siteId)
  },

  methods: {
    ...mapMutations('asset', [
      'code',
      'zone',
      'risk',
      'assetType'
    ]),

    save() {
      this.working = true
      this.$store.dispatch('asset/save').then(() => {
        this.working = false
        this.$toast.success('Saved')
      }).catch(error => {
        this.working = false
        this.$toast.error('Whoops')
      })
    }
  },

  computed: {
    ...mapGetters('zone', [
      'zoneList'
    ]),
    ...mapGetters('asset', [
      'asset'
    ]),
    ...mapGetters('riskLevel', [
      'riskLevelList'
    ]),
    ...mapGetters('assetType', [
      'assetTypeList'
    ]),
    assetTypeHasMetadata(){
        return this.asset?.type?.custom_fields_object?.length > 0
    },
    canSave() {
      const fields = this.asset.type.custom_fields
          ? JSON.parse(this.asset.type.custom_fields)
          : [];

      return fields
          .filter(field => field.is_required)
          .every(field => this.asset.meta_data?.[field.field_id])
    }
  }

}
</script>